import * as React from "react"

import Layout from "../../components/Layout"
import LuxuryResaleThanks from "../../components/LuxuryResalePage/LuxuryResaleThanks"


function luxuryresalethankyou({ data }) {
  return (
    <Layout>
      <LuxuryResaleThanks />

    </Layout>
  )
}

export default luxuryresalethankyou
