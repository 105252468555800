import React from 'react';
import { graphql, useStaticQuery } from "gatsby";
import "../scss/LuxuryResaleThanks.scss";
import { Helmet } from "react-helmet";

export default function LuxuryResaleThanks() {
  const data = useStaticQuery(graphql`
    query MyQuery3790thanks {
      allContentfulImage (filter: { name: { eq: "thankyou" } }){
        edges {
          node {
            id
            name
            image {
              file {
                url
              }
            }
          }
        }
      }
    }
    
  `);

  return (
    <div className="LuxuryResaleThankYouPageContainer">
      <div className="contentContainerLuxuryResaleThanks">
        <div className="imageContainerLuxuryResaleThanks">
          <img className="LuxuryResaleThanks" src={data.allContentfulImage.edges[0].node.image.file.url} />
        </div>
        <div className="LuxuryResaleThanksContainer">
            <p className="LuxuryResalethankYouTitle">Download the <br></br>Luxury Resale Report</p>
            <p className="LuxuryResalethankYouText">We’ve sent an email  with a verification link to the Business or Educational Email you have provided.</p>
            <p className="LuxuryResalethankYouText">Please verify it’s you via your email.</p>
        </div>
      </div>
    </div>


  );
}

